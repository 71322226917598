import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ListPage from 'pages/Motoristas/PerfilMotorista/components/ListPage';

// Services
import { columns } from './constants';
import { trackEvent } from 'utils/mixpanel';
import { statusTabs } from 'pages/Desvios/Listagem/constants';
import { requestCards, requestDesvios, requestTotaisDesvios } from './services';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const defaultFilters = {
  tecnologia: '',
  desvio: '',
  status: 'PENDENTE',
  responsavel: '',
  criticidade: '',
  sortBy: { id: 'data_desvio', order: 'DESC' },
};
export const Desvios = ({
  handleExport = () => {},
  handleFilter = () => {},
}) => {
  // Redux e hooks
  const { filiais } = useFetchMultipleWithCache();

  const { filials: filiais2 } = useSelector(state => {
    return state.selects;
  });

  const params = useParams();
  const user = useSelector(state => state.auth?.user?.user);

  const filter = useSelector(state => state.filterProvider);
  const [filterDesvios, setFilterDesvios] = useState(defaultFilters);

  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);

  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const { data: resTotal } = useQuery(
    ['totais-desvios-empresa', params?.id, query],
    () => query && requestTotaisDesvios(params?.id, query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isFetching, data: resData } = useQuery(
    ['desvios-empresa', params?.id, query],
    () => query && requestDesvios(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  const { isLoading: loadingCards, data: resCards } = useQuery(
    [
      'cards-desvios-empresa',
      params?.id,
      {
        ...filter,
        ...filterDesvios,
      },
    ],
    () =>
      requestCards(params?.id, {
        ...filter,
        ...filterDesvios,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterDesvios, selectedCard]);

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    setLoadingTab(true);
    setFilterDesvios(state => ({ ...state, status: newValue }));
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  // Atualiza as colunas de acordo com o status selecionado
  const handleColumns = () => {
    const col = [...columns].map(item => {
      if (item.id === 'createdAt') {
        return {
          ...item,
          subRow: {
            prefix: 'Vencimento:',
            value: (_, item) => item.data_vencimento || '-',
          },
        };
      }
      return item;
    });

    if (filterDesvios.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvios.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [
      {
        title: 'Abrir em nova guia',
        function: handleOpenNewTab,
      },
    ];

    return actions.filter(Boolean);
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvios/${id}`);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: filterDesvios.status,
    items: statusTabs
      .filter(item => item.value !== 'ABERTO')
      .map(item =>
        resTotal?.data
          ? {
              ...item,
              label: `${item.label} (${
                resTotal?.data?.data?.[0]?.[item.value] ?? 0
              })`,
            }
          : item,
      ),
    onChange: handleStatusTab,
    disabled: loadingCards || isFetching,
  };

  const filters = {
    data: filterDesvios,
    defaultFilters,
    setFilter: setFilterDesvios,
    loading: isFetching || loadingCards,
  };

  const totalStatus = resTotal?.data?.data?.[0] || {};
  let total = totalStatus[filterDesvios.status] || 0;
  if (selectedCard) {
    total = resCards?.find(card => card.type === selectedCard).value;
  }

  return (
    <ListPage
      // total={resTotal?.data?.data?.[0]?.TOTAL ?? 0}
      filters={filters}
      filterProps={{
        handleExport: () => handleExport(),
        handleFilters: values => handleFilter(values),
        data: [],
      }}
      datePicker
      headerTabs={headerTabs}
      cards={resCards?.map(card => ({ ...card, disabled: isFetching })) || []}
      selectedCard={selectedCard}
      handleClickCard={handleClickCard}
      loadingCards={loadingCards}
      tableProps={{
        data: resData?.data?.data || [],
        columns: handleColumns(),
        loading: isFetching || loadingTab,
        pageCount: total,
        visualizedKey: 'visto',
        local: false,
        actions: getActions(),
        reset: resetTable,
        onClickRow: handleOpenNewTab,
        loadingSelection: loadingLines,
        setQuery: q =>
          setQuery({
            ...filter,
            ...filterDesvios,
            ...q,
            card: selectedCard,
          }),
        searchEvent: search =>
          trackEvent(user, 'Busca Desvios de Direção', null, search),
        sortBy: { id: 'data_desvio', order: 'DESC' },
        placeholder: `Buscar por ID`,
        empty: {
          title: 'Ops! Você não tem nenhum desvio disponível.',
          description: 'Verifique os filtros aplicados!',
        },
      }}
    />
  );
};
