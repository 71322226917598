import styled, { css } from 'styled-components';

export const List = styled.div`
  ${() => css`
    width: 100%;
  `}
`;

export const Detail = styled.div`
  ${() => css`
    width: 100%;
  `}
`;
