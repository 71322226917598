// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setGerais,
  setTitle,
  setCapa as setCapaRedux,
} from 'store/modules/formularios/actions';

// Components
import Calendar from 'components/Inputs/Calendar';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import { Switch } from 'components/Inputs/Switch';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';
import { ReactComponent as Seguir } from 'images/formulario/seguir.svg';
import { ReactComponent as Seguindo } from 'images/formulario/seguindo.svg';

// Components MUI
import AddIcon from '@mui/icons-material/Add';
import { Grid, IconButton, Link, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Utils
import { useNavigate } from 'react-router-dom';
import { steps } from '../constants';
import * as services from '../services';
import { toggleFollow } from 'pages/Fomularios/services';
import Radio from '../../Fields/Components/Radio';
import ModalFrequency from './FrequencyModal';
import ModalNewTag from './NewTagModal';
import Tag from '../../components/Tag';
import * as S from './styled';

export const InformacoesGerais = ({
  setChanged,
  trySave,
  setTrySave,
  setCurrentStep,
  setLoading,
  isEdit,
  tags,
  isPublished,
  setFormPrev,
  refetch,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Capa default Header
  const img = require(`images/defaultFormulario.jpg`);

  // Redux
  const dispatch = useDispatch();
  const formularios = useSelector(state => state.formularios);

  // From States
  const [titulo, setTitulo] = useState(formularios.titulo);
  const [descricao, setDescricao] = useState('');
  const [capa, setCapa] = useState(formularios.capa);
  const [data_inicio, setDataInicio] = useState(new Date());
  const [data_fim, setDataFim] = useState('');
  const [final, setFinal] = useState(false);
  const [frequencia, setFrequencia] = useState('');
  const [periodo, setPeriodo] = useState('');
  const [dias_da_semana, setDiasDaSemana] = useState([]);
  const [nunca_repetir, setNuncaRepetir] = useState(true);
  const [categories, setCategories] = useState([]);
  const [multiple, setMultiple] = useState(false);

  // Modal
  const [openModalTag, setOpenModalTag] = useState(false);
  const [frequencyModal, setFrequencyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disbleFrequency, setDisableFrequency] = useState(false);

  // -------------------------------------INITIALIZE DATA-----------------------------------------//
  useEffect(() => {
    setTitulo(formularios.titulo || '');
    setCapa(formularios.capa);

    let tagsCat = [];
    tagsCat = tags.map(i => ({ state: false, name: i }));

    if (formularios.gerais) {
      const { gerais } = formularios;
      setDescricao(gerais.descricao || '');
      setDataInicio(gerais.data_inicio || new Date());
      setDataFim(gerais.data_fim || '');
      setFinal(!!gerais.data_fim);
      setFrequencia(gerais.frequencia);
      setPeriodo(gerais.periodo);
      setDiasDaSemana(gerais.dias_semana);
      setNuncaRepetir(!gerais.repetir);
      setCapa(gerais.capa);
      setMultiple(!!gerais?.multiplo);

      let formTags = gerais.tags || [];

      if (formTags[0] && typeof formTags[0] === 'object')
        formTags = formTags.map(item => item.tag);
      const hasInclude = [];

      for (const i in tagsCat) {
        if (formTags.includes(tagsCat[i].name)) {
          tagsCat[i].state = true;
          hasInclude.push(tagsCat[i].name);
        }
      }

      const diff = formTags.filter(tag => hasInclude.indexOf(tag) === -1);

      for (const i in diff) {
        tagsCat.push({
          state: true,
          name: diff[i],
        });
      }

      if (!gerais.descricao) {
        setChanged(true);
      }
    }

    setCategories(tagsCat);
  }, [formularios]);

  // -------------------------------------IMAGE CONTROLL-----------------------------------------//
  const addImage = items => {
    setCapa(items[0].imagem_url);
  };

  // -------------------------------------TAGS CONTROLL-----------------------------------------//
  const toogleTag = key => {
    if (!formularios.isEditable) return;
    const clTags = [...categories];
    clTags[key].state = !clTags[key].state;
    setCategories(clTags);
    setChanged(true);
  };

  // -------------------------------------PAGE CONTROLL-----------------------------------------//
  const formatMessage = () => {
    let base = null;

    switch (periodo) {
      case 'DIA':
        base = frequencia > 1 ? 'dias' : 'dia';
        break;
      case 'SEMANA':
        base = frequencia > 1 ? 'semanas' : 'semana';
        break;
      case 'MES':
        base = frequencia > 1 ? 'meses' : 'mes';
        break;
    }

    const mas = ['Sabado', 'Domingo'];
    const fem = ['Segunda', 'Terca', 'Quarta', 'Quinta', 'Sexta'];
    let textDay = null;
    if (
      periodo === 'SEMANA' &&
      dias_da_semana &&
      mas.includes(dias_da_semana)
    ) {
      textDay = `todo ${dias_da_semana.toLowerCase()},`;
    } else if (
      periodo === 'SEMANA' &&
      dias_da_semana &&
      fem.includes(dias_da_semana)
    ) {
      textDay = `toda ${dias_da_semana.toLowerCase()},`;
    }

    return `A cada ${frequencia} ${base}, ${textDay || ''} ${
      final
        ? `até ${new Date(data_fim).toLocaleDateString()}`
        : 'nunca encerrar'
    }`;
  };

  const changingMonitoring = (func, value, type) => {
    if (!value && !func) {
      setChanged(true);
      return;
    }

    func(value);
    setChanged(true);
  };

  const handleMutiple = () => {
    if (!nunca_repetir && !multiple) {
      setDisableFrequency(true);
    } else {
      setMultiple(!multiple);
    }
  };

  const handleConfirmMultiple = () => {
    setDisableFrequency(false);
    setMultiple(true);
    setNuncaRepetir(true);
    setChanged(true);
  };

  // -------------------------------------SAVE FORM-----------------------------------------//
  useEffect(() => {
    if (trySave) {
      // Validações
      if (!titulo) {
        toast.error('Insira um título para o fomulário');
        setTrySave(false);
        return;
      }

      if (!descricao) {
        toast.error('Insira uma descrição para o fomulário');
        setTrySave(false);
        return;
      }

      if (!data_inicio) {
        toast.error('Insira a data de início do formulário');
        setTrySave(false);
        return;
      }

      if (final && !data_fim) {
        toast.error(
          'Defina a data encerramento ou marque como "Nunca encerrar"',
        );
        setTrySave(false);
        return;
      }

      if (new Date(data_fim) < new Date(data_inicio)) {
        toast.error('Defina uma data incial menor que a data final');
        setTrySave(false);
        return;
      }

      // Organiza o objeto e faz a request
      const tags = [];
      for (const i in categories) {
        if (categories[i].state) {
          tags.push({ tag: categories[i].name });
        }
      }

      if (tags?.length > 6) {
        toast.error('Selecione no máximo 6 tags');
        setTrySave(false);
        return;
      }

      const data = {
        id: formularios.id,
        pagina: 'geral',
        titulo,
        descricao,
        capa,
        data_inicio,
        data_fim: final ? data_fim : null,
        frequencia: Number(frequencia),
        periodo: periodo || null,
        dias_semana: dias_da_semana,
        repetir: !nunca_repetir,
        multiplo: multiple,
        tags,
      };

      sendRequest(data);
    }
  }, [trySave]);

  const sendRequest = async data => {
    setLoading(true);
    const res = await services.SaveGeneralInfo(data);

    if (res.success) {
      // TODO: Unificar redux
      dispatch(setGerais({ ...formularios.gerais, ...res.data }));
      dispatch(setCapaRedux(res.data.capa));
      dispatch(setTitle(res.data.titulo));
      toast.success(res.message);
      setChanged(false);
      setCurrentStep(steps[1]);
      setFormPrev(null);
    } else {
      toast.error(res.message);
    }

    setTrySave(false);
    setLoading(false);
  };

  const handleToggleFollow = async (id, follow) => {
    const res = await toggleFollow(id, follow);
    if ([200, 201].includes(res.status)) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    refetch();
  };

  // -------------------------------------DELETE FORM-----------------------------------------//
  const deleteForm = async () => {
    const res = await services.DeleteForm(formularios.id);

    if (res.success) {
      toast.success(res.message);
      setDeleteModal(true);
      navigate('/formularios');
    } else {
      toast.error(res.message);
    }
  };

  // -------------------------------------RENDER-----------------------------------------//
  return (
    <>
      {formularios.gerais?.isFollowing !== null && (
        <S.Follow following={formularios.gerais?.isFollowing}>
          {formularios.gerais?.isFollowing ? (
            <>
              <Seguindo width={48} height={48} />
              <div className="label">
                <h2>Você está seguindo esse formulário</h2>
                <Link
                  component="button"
                  onClick={() => handleToggleFollow(formularios.id, false)}
                  color="inherit"
                >
                  Parar de seguir
                </Link>
              </div>
            </>
          ) : (
            <>
              <Seguir width={48} height={48} />
              <div className="label">
                <h2>Você não segue esse formulário</h2>
                <Link
                  component="button"
                  onClick={() => handleToggleFollow(formularios.id, true)}
                  color="inherit"
                >
                  Siga para ser notificado sobre respostas reprovadas
                </Link>
              </div>
            </>
          )}
        </S.Follow>
      )}
      <S.Box>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} md={12}>
            <TextInput
              disabled={!formularios.isEditable}
              required
              label="Titulo"
              onChange={e =>
                changingMonitoring(
                  setTitulo,
                  e.target.value.slice(0, 50),
                  'titulo',
                )
              }
              placeholder="Digite de maneira resumida o tema do formulário"
              value={titulo}
            />
            <S.Counter>{`${titulo?.length}/50`}</S.Counter>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextInput
              disabled={!formularios.isEditable}
              label="Descrição"
              placeholder="Descreva brevemente os assuntos que serão abordados."
              value={descricao}
              onChange={e =>
                changingMonitoring(
                  setDescricao,
                  e.target.value.slice(0, 300),
                  'desc',
                )
              }
              multiline
              required
              rows={6}
            />
            <S.Counter>{`${descricao?.length}/300`}</S.Counter>
          </Grid>

          <Grid item xs={6} md={6}>
            <Calendar
              value={new Date(data_inicio)}
              onChange={e => changingMonitoring(setDataInicio, e)}
              futureDate
              pastDate={false}
              label="Data de início"
              style={{ maxWidth: '100%' }}
              disabled={isPublished}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <S.FinishBox>
              <span className="textBox">
                <Radio
                  style={{ marginRight: '4px' }}
                  value={!final}
                  disabled
                  label="Nunca encerrar"
                  onChange={() => {
                    setFinal(!final);
                    setChanged(true);
                  }}
                />
              </span>
              <Calendar
                value={final ? new Date(data_fim) : ''}
                onChange={e => changingMonitoring(setDataFim, e)}
                futureDate
                pastDate={false}
                label="Data de encerramento"
                style={{
                  maxWidth: '100%',
                  opacity: final ? 1 : 0.3,
                }}
                disabled={!final}
              />
            </S.FinishBox>
          </Grid>

          <Grid item xs={6} md={6}>
            <S.Label>Repetição</S.Label>
            <S.FalseSelect
              onClick={
                !isPublished
                  ? () => setFrequencyModal(true)
                  : () =>
                      toast.error(
                        'Não é possível alterar a frequência de formulários publicados',
                      )
              }
              isPublished={isPublished}
            >
              <div>{nunca_repetir ? 'Não repetir' : formatMessage()}</div>
              {!isPublished && (
                <ExpandMoreIcon htmlColor={theme.palette.brand.primary.light} />
              )}
            </S.FalseSelect>
          </Grid>

          <Grid item xs={6} md={6}>
            <S.Label>
              <span>Múltiplas Respostas</span>
              <Tooltip title="Permite que o usuário responda mais de uma vez ao mesmo formulário. A repetição será desabilitada caso esse item esteja ativo!">
                <HelpOutlineOutlinedIcon
                  htmlColor={theme.palette.semantics.feedback.unknown.natural}
                />
              </Tooltip>
            </S.Label>
            <S.MultipleContent isPublished={isPublished}>
              <Switch
                textOn="Habilitado"
                textOff="Desabilitado"
                check={multiple}
                setCheckbox={() => handleMutiple()}
                disabled={isPublished}
              />
            </S.MultipleContent>
          </Grid>

          <Grid item xs={12} md={12}>
            <DropZoneArchives
              noTopButton={!formularios.isEditable}
              onlyRead={!formularios.isEditable}
              data={
                capa
                  ? { imagem_url: capa, legenda: '' }
                  : { imagem_url: img, legenda: '' }
              }
              multiple={false}
              fileDir="formularios/capas"
              addFunction={addImage}
              changeMonitoring={changingMonitoring}
              automaticSave
              label="Capa Principal"
              titleButton="Alterar Capa"
              unicCenter
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              style={{
                flex: 1,
                flexDirection: 'col',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <div>
                <h3>Selecione a categoria</h3>
              </div>
              <S.BoxTags>
                {categories.map((i, k) => (
                  <Tag
                    key={k}
                    children={i.name}
                    active={i.state}
                    onClick={() => toogleTag(k)}
                  />
                ))}
                <IconButton
                  disabled={!formularios.isEditable}
                  onClick={() => setOpenModalTag(true)}
                >
                  <AddIcon />
                </IconButton>
              </S.BoxTags>
            </div>
          </Grid>
          {isEdit && (
            <Grid item xs={6} md={6}>
              <GhostButton
                disabled={!formularios.isEditable}
                children="Excluir fomulário"
                customcolor={theme.palette.semantics.feedback.attention.natural}
                onClick={() => setDeleteModal(true)}
              />
            </Grid>
          )}

          {/* MODAL AREA */}
          <ModalNewTag
            open={openModalTag}
            handleClose={() => setOpenModalTag(false)}
            categories={categories}
            handleSave={tags => {
              setCategories(tags);
              setOpenModalTag(false);
              setChanged(true);
            }}
          />

          <ModalFrequency
            // Form States
            Ofrequencia={frequencia}
            OsetFrequencia={setFrequencia}
            Operiodo={periodo}
            OsetPeriodo={setPeriodo}
            Odias_da_semana={dias_da_semana}
            OsetDiasDaSemana={setDiasDaSemana}
            Onunca_repetir={nunca_repetir}
            OsetNuncaRepetir={setNuncaRepetir}
            Odata_final={data_fim}
            OsetDataFinal={setDataFim}
            Ofinal={final}
            OsetFinal={setFinal}
            data_inicio={data_inicio}
            // Modal States
            setChanged={setChanged}
            open={frequencyModal}
            onClose={() => setFrequencyModal(false)}
            multiple={multiple}
            setMultiple={setMultiple}
          />

          <ConfirmModal
            open={deleteModal}
            titleIcon={
              <DeleteOutlineOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.attention.natural}
              />
            }
            title="Deseja excluir este formulário?"
            description="Todos os dados referentes a ele serão excluídos da plataforma Onisys."
            subtitle="Todos os dados referentes a ele serão excluídos da plataforma Onisys."
            handleClose={() => setDeleteModal(false)}
            onClick={deleteForm}
            buttonText="Excluir"
            isNegative
          />

          <ConfirmModal
            open={disbleFrequency}
            titleIcon={
              <WarningAmberOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.attention.natural}
              />
            }
            title="Tem certeza que deseja habilitar?"
            subtitle="A repetição será desabilitada e o formulário estará disponível para respostas todos os dias até a data de encerramento."
            handleClose={() => setDisableFrequency(false)}
            onClick={() => handleConfirmMultiple()}
            buttonText="Habilitar"
          />
        </Grid>
      </S.Box>
    </>
  );
};
