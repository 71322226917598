import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

import { Divider, Grid, IconButton } from '@mui/material';
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from '@mui/icons-material';
import { Actions } from 'components/Actions';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';
import ImageViolacao from '../../../../components/ImageViolacao';
import MapDesvio from '../../../../components/MapDesvio';
import { getCriticidadeColor } from '../../../constants';

import { ReactComponent as ArrowRight } from 'images/icons/arrows/right2.svg';
import { ReactComponent as ExpandIcon } from 'images/icons/buttons/expand.svg';

import { formatNewHourClean } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';
import * as S from './styled';

export const Detail = ({ infraction, onClose, actions, onOpen }) => {
  const imgCurrentPosistion = require(`images/icone-local.png`);

  const theme = useTheme();
  const [view, setView] = useState('video');

  const { isOpLogistico, isProvider, hasTorrePlus } = usePlans();

  const renderMapa = () => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        display="flex"
        justifyContent="center"
        position="relative"
      >
        {(infraction?.caminho_cloud ||
          infraction?.video ||
          infraction?.imagem) &&
        view === 'video' ? (
          <>
            <ImageViolacao
              video={infraction?.caminho_cloud ?? infraction?.video}
              imagem={infraction?.imagem}
              frame_pos={infraction?.frame_pos}
            />
            <S.AbsoluteIconButton
              sx={{
                right: -16,
              }}
              onClick={() => setView('mapa')}
            >
              <NavigateNextRounded />
            </S.AbsoluteIconButton>
          </>
        ) : (
          <>
            {infraction.latitude && view === 'mapa' && (
              <S.AbsoluteIconButton
                sx={{
                  left: -16,
                }}
                onClick={() => setView('video')}
              >
                <NavigateBeforeRounded />
              </S.AbsoluteIconButton>
            )}

            <MapDesvio
              position={{
                lat: parseFloat(infraction.latitude),
                lng: parseFloat(infraction.longitude),
              }}
              otherPosition={
                infraction.latitude_anterior
                  ? {
                      lat: parseFloat(infraction.latitude_anterior),
                      lng: parseFloat(infraction.longitude_anterior),
                    }
                  : null
              }
            />
          </>
        )}
      </Grid>
    );
  };

  const getEndereco = useCallback(infraction => {
    if (infraction?.endereco)
      return `${infraction.endereco} (lat: ${infraction.latitude}, lng: ${infraction.longitude})`;
    return infraction?.latitude
      ? `lat: ${infraction.latitude}, lng: ${infraction.longitude}`
      : 'Não informado';
  }, []);

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <S.Header>
          <S.Flex>
            <DefaultButton width="32px" onClick={onClose}>
              <ArrowRight />
            </DefaultButton>
            <S.LabelId>
              {infraction?.id
                ? `#${String(infraction.id).substring(0, 8)}`
                : ''}
            </S.LabelId>
            <S.Flex>
              <S.Criticidade
                color={getCriticidadeColor(infraction.criticidade, theme)}
              >
                <h3>
                  {infraction?.criticidade
                    ? infraction.criticidade.toLowerCase()
                    : ''}
                </h3>
              </S.Criticidade>
            </S.Flex>
          </S.Flex>

          <S.Flex>
            <IconButton
              style={{ borderRadius: 4 }}
              onClick={() => onOpen(infraction.id, infraction)}
            >
              <ExpandIcon color={theme.palette.words.subtitle.light} />
            </IconButton>
            <Actions id={infraction.id} options={actions} item={infraction} />
          </S.Flex>
        </S.Header>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          justifyContent="center"
          position="relative"
        >
          {infraction?.latitude ? (
            renderMapa()
          ) : (
            <ImageViolacao
              video={infraction?.caminho_cloud ?? infraction?.video}
              imagem={infraction?.imagem ?? infraction?.arquivo_evidencia}
              frame_pos={infraction?.frame_pos}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <S.DriverContainer>
            {!infraction.id_motorista &&
            (!isProvider || isOpLogistico || hasTorrePlus) ? (
              <>
                <h2>Motorista não identificado</h2>
                <h3>{`Filial: ${
                  infraction?.filial_placa ?? 'Não definida'
                }`}</h3>
              </>
            ) : (
              <>
                <h2>
                  {!isProvider || isOpLogistico || hasTorrePlus
                    ? infraction.motorista_nome?.toLowerCase()
                    : infraction.empresa_nome?.toLowerCase()}
                </h2>
                <h3>
                  {`Filial: ${infraction?.filial_nome ?? 'Não definida'}`}
                </h3>
              </>
            )}
          </S.DriverContainer>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextInput
            id="desvio"
            label="Desvio"
            value={infraction.titulo ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
            title={infraction.desvio_tipo?.titulo}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextInput
            id="data_desvio"
            label="Data do desvio"
            value={formatNewHourClean(infraction.data_desvio, true)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="placa"
            label="Placa"
            value={`${infraction?.placa} (Filial:${infraction?.filial_placa})`}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        {isProvider ? (
          <Grid item xs={12} sm={6}>
            <TextInput
              id="cliente"
              label="Cliente"
              value={infraction?.distribuidora_nome ?? ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6}>
            <TextInput
              id="empresa"
              label="Empresa"
              value={infraction?.empresa_nome ?? ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}

        {(infraction.velocidade || infraction.velocidade === 0) && (
          <>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="velocidade"
                label="Velocidade"
                value={`${infraction.velocidade} km/h`}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id="velocidade_via"
                label="Limite de velocidade"
                value={
                  infraction.desvio_tipo?.limiar || infraction.velocidade_via
                    ? `${infraction.velocidade_via} km/h`
                    : 'Não informado'
                }
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          </>
        )}

        {(infraction?.endereco || infraction?.latitude) && (
          <Grid item xs={12} sm={12}>
            <TextInput
              startIcon={
                <img
                  src={imgCurrentPosistion}
                  alt="icon localizacao"
                  height={30}
                  width={24}
                />
              }
              id="local"
              label="Localização"
              value={getEndereco(infraction)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}
      </Grid>
    </S.ColumnWrapper>
  );
};
