import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${`${theme.palette.system.natural}30`};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.words.subtitle.natural};
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    span {
      margin-right: 7px;
    }
  `}
`;

export const FalseSelect = styled.div`
  ${({ theme, isPublished }) => css`
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${theme.palette.words.subtitle.natural};
    width: 100%;
    padding: 13px 20px;
    border-radius: 4px;

    div {
      margin-right: 5px;
      font-weight: 500;
      color: ${theme.palette.words.subtitle.natural};
      opacity: ${isPublished ? 0.5 : 1};
    }
  `}
`;

export const MultipleContent = styled.div`
  opacity: ${({ isPublished }) => (isPublished ? 0.5 : 1)};
`;

export const BoxTags = styled.div`
  margin-top: 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  gap: 1;
`;

export const FinishBox = styled.div`
  position: relative;

  .textBox {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`;

export const Counter = styled.span`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 11px;
    font-weight: 600;
    padding-right: 3px;
    color: ${theme.palette.words.title.natural};
  `}
`;

export const Follow = styled.div`
  ${({ theme, following }) => css`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    color: ${theme.palette.brand.secondary.natural};
    width: 50%;
    background-color: ${following
      ? theme.palette.system.border
      : theme.palette.system.overlay};
    border: 2px solid ${`${theme.palette.system.natural}30`};
    border-radius: 4px;

    h2 {
      font-size: 1.25rem;
      color: ${theme.palette.words.title.natural};
    }

    .label {
      color: ${theme.palette.brand.secondary.natural};
    }
  `}
`;
