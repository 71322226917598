import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { usePlans } from 'hooks/usePlans';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import Calendar from 'components/Inputs/Calendar';
import TextInput from 'components/Inputs/TextField';
import * as S from './styled';
import { useQuery } from 'react-query';
import { getResponsaveisProvider } from '../DesvioManualModal/services';
import SelectMultiple from 'pages/Configuracoes/EscalationList/NovaLista/components/SelectMultiple';

import { requestNiveis, getResponsaveisDesvio } from '../../Listagem/services';

const ResponsibleModal = ({
  ids,
  handleClose,
  handleConfirm,
  titleIconName = 'check',
  loading,
  idFilialDesvios,
  approve = false,
}) => {
  const theme = useTheme();

  const { isProvider } = usePlans();
  const selects = useSelector(state => {
    return state.selects;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const grupos = [
    { value: 1, label: 'Administrador' },
    { value: 2, label: 'Analista' },
    { value: 3, label: 'Operacional' },
    { value: 4, label: 'Convidado' },
  ];

  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;

  const [responsaveis, setResponsaveis] = useState([]);

  const { data: responsaveisProvider = [] } = useQuery(
    ['responsaveis-provider'],
    () => getResponsaveisProvider(),
    {
      enabled: user.provider,
      onSuccess: (data = []) => {
        if (isProvider)
          setResponsaveis(
            data.map(item => ({
              id: item.id,
              value: item.nome,
              grupo: item.nivel,
            })),
          );
      },
      refetchOnWindowFocus: false,
    },
  );

  const { data: dataSelects } = useQuery(
    ['responsaveis-desvio'],
    () => getResponsaveisDesvio(),
    {
      onSuccess: (data = []) => {
        if (!isProvider) setResponsaveis(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const title = useMemo(() => {
    return ids.length > 1
      ? `Deseja ${
          approve ? 'aprovar' : 'atribuir responsável para'
        } os desvios selecionados? (${ids.length})`
      : `Deseja ${
          approve ? 'aprovar' : 'atribuir responsável para'
        } o desvio selecionado?`;
  }, [ids]);

  const subtitle = `Todos os desvios selecionados ${
    approve
      ? 'serão movidos para a aba pendentes.'
      : 'terão o mesmo responsável.'
  }`;

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      {titleIconName}
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  const [data, setData] = useState({
    ids_responsaveis: '',
    data_previsao: new Date(),
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (!data.ids_responsaveis) ready = false;
    else if (!data.data_previsao) ready = false;
    else if (!data.plano_acao) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = {
      ...data,
      ids,
    };
    handleConfirm(req, approve);
  };

  const open = Boolean(ids);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              required
              label="Plano de Ação"
              value={data.plano_acao}
              onChange={e => handleChange('plano_acao', e.target.value)}
              name="plano_acao"
            />
          </Grid>
          <Grid item xs={8} sm={8}>
            <SelectMultiple
              grupos={grupos}
              width="100%"
              data={responsaveis}
              label={
                <span style={{ color: 'rgb(101, 110, 140)' }}>
                  Atribuir Responsável
                </span>
              }
              value={data.ids_responsaveis}
              handleChange={val => handleChange('ids_responsaveis', val)}
              required
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Calendar
              value={data.data_previsao}
              onChange={newDate => handleChange('data_previsao', newDate)}
              futureDate
              pastDate={false}
              label="Data de previsão"
              required
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            size="medium"
            onClick={handleClose}
          >
            Cancelar
          </GhostButton>

          <GhostButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkReady()}
            loading={loading}
          >
            Confirmar
          </GhostButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default ResponsibleModal;
