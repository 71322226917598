// Styled

// React
import { useEffect, useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';

// Components MUI
import { Divider } from '@mui/material';
import { OutQuestion } from '..';
import * as S from './styled';

export const ResponseDisabled = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  preview,
}) => {
  const [value, setValue] = useState();

  useEffect(() => {
    if (field.id_tipo_campo === 18) {
      const date = field.resposta ? new Date(field.resposta) : new Date();

      if (isNaN(date.getTime())) {
        setValue(field.resposta);
        return;
      }

      const pad = num => num.toString().padStart(2, '0');

      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1);
      const year = date.getFullYear();
      const hour = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());

      setValue(`${day}/${month}/${year} ${hour}:${minutes}:${seconds}`);
    } else {
      setValue(field.resposta);
    }
  }, [field]);

  return (
    <>
      <S.QuestionBox className={`fieldClass-${field.id}`}>
        <OutQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          preview={preview}
        />
        <S.ResponseArea>
          <TextInput
            noAsterisk
            value={value}
            disabled
            style={{
              width: preview ? '100%' : '50%',
              maxWidth: preview ? 'auto' : '300px',
            }}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
