import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  ${({ theme, ...props }) => css`
    &.MuiButton-root {
      min-width: ${String(props.width).includes('px') ? props.width : '120px'};
      width: ${props.width || ''};
      cursor: ${props.loading === 'true' ? 'default' : 'pointer'};

      background: ${theme.palette.brand.secondary.natural};
      border-radius: 4px;

      font-size: 16px;
      font-weight: 500;
      text-transform: none;
      color: ${theme.palette.words.text.contrast};

      &:hover {
        background: ${theme.palette.brand.secondary.light};
      }

      &:focus {
        background: ${theme.palette.brand.secondary.natural};
        box-shadow: 0px 3px 20px ${theme.palette.brand.secondary.natural};
      }

      &:active {
        background: ${theme.palette.brand.secondary.natural};
        box-shadow: 0px 3px 15px ${theme.palette.brand.secondary.natural};
      }
    }

    &.MuiButton-sizeSmall {
      height: 24px;
      font: normal normal 500 14px/24px Texta;
    }

    &.MuiButton-sizeMedium {
      height: 32px;
      font: normal normal 500 16px/18px Texta;
    }

    &.MuiButton-sizeLarge {
      height: 40px;
      font: normal normal 500 18px/24px Texta;
    }

    &&.Mui-disabled {
      background: ${theme.palette.system.disabled};
      color: ${theme.palette.words.disabled.natural};
    }
  `}
`;
