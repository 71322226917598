import api from 'services/api';
import { iconsCards } from 'pages/Desvios/Listagem/constants';

export const requestDesvios = async (id, query) => {
  const res = await api.get(`/drivers/perfil/desvios/${id}`, { params: query });
  return res;
};

export const requestTotaisDesvios = async (id, query) => {
  const res = await api.get(`/drivers/perfil/desvios/total/${id}`, {
    params: query,
  });
  return res;
};

export const requestCards = async (id, query) => {
  const res = await api.get(`/drivers/perfil/desvios/cards/${id}`, {
    params: query,
  });

  if (res?.data?.data?.data)
    return res.data.data.data.map(card => ({
      ...card,
      icon: iconsCards[card.type],
    }));
  return [];
};
