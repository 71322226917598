export const columns = [
  {
    header: 'Nome',
    id: 'nome_aluno',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Tipo',
    id: 'tipo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Filial',
    id: 'filial',
    type: 'string',
    sort: true,
  },
];
