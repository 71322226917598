import { useState } from 'react';
import { Table } from '../../components';
import { Detail, List } from './styed';
import { useEffect } from 'react';

export const ItemDetailTable = ({
  data,
  bulk,
  empty,
  reset,
  sizes,
  sortBy,
  persist,
  columns,
  actions,
  loading,
  loadingCounter,
  setQuery,
  pageCount,
  emptyFill,
  hideLines,
  fixedItems,
  searchKeys,
  compactRow,
  searchEvent,
  placeholder,
  visibleBulk,
  notClickable,
  actionsTitle,
  cachePerPage,
  disabledRows,
  local = true,
  setSelectedRows,
  loadingSelection,
  selectedByDefault,
  renderDetail,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClickRow = (_, row) => setSelectedItem(row);
  const handeClose = () => setSelectedItem(null);

  useEffect(() => {
    if (reset) handeClose();
  }, [reset]);

  return (
    <Table.Provider persist={persist}>
      <Table.Container transparent>
        {!local && <Table.Dynamic setQuery={setQuery} sortBy={sortBy} />}
        <Table.Header transparent>
          <Table.Input
            local={local}
            loading={loading}
            placeholder={placeholder}
            searchEvent={searchEvent}
          />
          <Table.Bulk options={bulk} disabled={loading} visible={visibleBulk} />
        </Table.Header>
        <Table.BodyWrapper>
          <List>
            <Table.Body
              data={data.map(item =>
                selectedItem && item.id === selectedItem?.id
                  ? { ...item, _selected: true }
                  : item,
              )}
              local={local}
              empty={empty}
              reset={reset}
              sortBy={sortBy}
              columns={
                selectedItem
                  ? columns.filter(item => item.showInDetail)
                  : columns
              }
              actions={!selectedItem && actions}
              loading={loading}
              pageCount={pageCount}
              emptyFill={emptyFill}
              hideLines={hideLines}
              compactRow={compactRow}
              onClickRow={handleClickRow}
              fixedItems={fixedItems}
              searchKeys={searchKeys}
              transparent
              cachePerPage={cachePerPage}
              disabledRows={disabledRows}
              actionsTitle={actionsTitle}
              notClickable={notClickable}
              visualizedKey="_selected"
              setSelectedRows={setSelectedRows}
              loadingSelection={loadingSelection}
              selectedByDefault={selectedByDefault}
            />
            <Table.Footer>
              <Table.Counter
                sizes={sizes}
                fixedValue={fixedItems}
                disabled={loading || loadingCounter}
                loading={loadingCounter}
              />
              <Table.Pagination disabled={loading || loadingCounter} />
            </Table.Footer>
          </List>
          {selectedItem && renderDetail instanceof Function && (
            <Detail>{renderDetail(selectedItem, handeClose)}</Detail>
          )}
        </Table.BodyWrapper>
      </Table.Container>
    </Table.Provider>
  );
};
