import api from 'services/api';

export const GetForms = async () => {
  const res = await api.get('/forms');
  return res.data;
};

export const CreateTitleForm = async titulo => {
  const res = await api.post('/form', { titulo });
  return res;
};

export const SaveFields = async data => {
  const res = await api.put(`/form/${data.id}`, data);
  return res.data;
};

export const getTemplate = async id => {
  const res = await api.get(`/form/template/${id}`);
  return res.data;
};

export const getFomrItem = async id => {
  const res = await api.get(`/form/${id}`);
  return res.data;
};

export const toggleFollow = async (id, follow) => {
  const res = follow
    ? await api.post(`/forms/follow/${id}`)
    : await api.delete(`/forms/unfollow/${id}`);
  return res;
};
