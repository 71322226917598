import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';

import { Grid } from '@mui/material';
import { Check, SaveAlt, FindInPageOutlined } from '@mui/icons-material';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import Loading from 'components/Loading';
import JustifyModal from 'components/JustifyModal';
import { usePlans } from 'hooks/usePlans';

import { useTheme } from 'styled-components';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { changeHeaderStatus } from 'store/modules/header/actions';
import {
  fetchDesvio,
  salvarDesvio,
  excluirDesvio,
  aprovarDesvios,
  finalizarDesvios,
  analisarDesvios,
  retornarDesvios,
  revisaoExclusaoProvider,
} from '../Listagem/services';
import { getStatusColor } from '../Listagem/constants';
import DeleteModal from '../components/DeleteModal';
import { ExportToPdf } from './Export';
import * as S from './styled';
import Config from './Config';
import Info from './Info';
import NextPrev from './NextPrev';
import RetornoModal from '../components/RetornoModal';

const DetalheDesvio = () => {
  const theme = useTheme();
  const printRefPdf = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user?.user);
  const userLevel = user.nivel;
  const { users, clients } = useSelector(state => {
    return state.selects;
  });
  const { isProvider } = usePlans();
  const [desvioDeleteModal, setDesvioDeleteModal] = useState(null);
  const [desvioRetornoModal, setDesvioRetornoModal] = useState(null);

  let isConvidado = false;
  if (+userLevel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para ver esta página!');
    navigate('/desvios');
  };

  //  ------------------ REVISAO DE EXCLUSÃO)----------------//
  const [openRevisaoExclusao, setOpenRevisaoExclusao] = useState({
    event: false,
    observacao_distribuidora: '',
    title: '',
    subtitle: '',
  });

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState(null);

  const componentRef = useRef();

  const {
    data: infraction,
    isFetching,
    refetch,
  } = useQuery(['desvios', params.id], () => fetchDesvio(params.id, {}), {
    onSuccess: data => {
      if (data) {
        if (isConvidado && !data?.ids_responsaveis?.includes(user.id)) {
          handleConvidado();
          return;
        }
        setFormData({
          id_distribuidora: data.id_distribuidora,
          ids_responsaveis: data.ids_responsaveis,
          data_previsao: data.data_previsao,
          plano_acao: data.desvio_info?.plano_acao,
          fator_risco: data.desvio_info?.fator_risco,
          arquivo_evidencia: data.desvio_info?.arquivo_evidencia,
          arquivo_plano_acao: data.desvio_info?.arquivo_plano_acao,
          observacao: data.desvio_info?.observacao,
          objeto: data.objeto,
        });

        // STATUS em header
        let rev = '';
        if (data?.aprovado_distribuidora === 0) rev = ' (Revisão de exclusão)';
        else if (data?.aprovado_distribuidora === 3)
          rev = ' (Segunda revisão de exclusão)';
        else if (data?.aprovado_distribuidora === 1)
          rev = ' (Revisão de exclusão APROVADA)';
        else if (data?.aprovado_distribuidora === 2)
          rev = ' (Revisão de exclusão RECUSADA)';

        if (data?.revisao_da_operacao === 'PENDENTE')
          rev += ' (Revisão de cliente)';
        else if (data?.revisao_da_operacao === 'APROVADA')
          rev += ' (Revisão de cliente APROVADA)';
        else if (data?.revisao_da_operacao === 'RECUSADA')
          rev += ' (Revisão de cliente RECUSADA)';

        dispatch(
          changeHeaderStatus({
            status: data.status + rev,
            color: getStatusColor(data.status, theme),
            background: `${getStatusColor(data.status, theme)}30`,
          }),
        );
        if (data?.em_analise === 'ANALISE' && userLevel === 1) {
          toast.warning('Foi solicitada a análise desse desvio.');
        }
        if (data?.em_analise === 'ANALISE' && userLevel > 1) {
          toast.warning('Desvio em análise.');
        }
        if (data?.em_analise === 'RETORNO' && userLevel > 1) {
          toast.warning('O administrador retornou o desvio da análise.');
        }

        // Alertas para transportador
        if (!isProvider) {
          if (
            data.status === 'ABERTO' &&
            (data?.aprovado_distribuidora === 0 ||
              data?.aprovado_distribuidora === 3)
          ) {
            toast.warning(
              'Desvio está em revisão de exclusão. Não é possivel alterar o cliente.',
            );
          }
          // TODO: ver se isso ainda existe
          if (data?.avaliacao?.desvios_delete_review?.length > 0) {
            toast.warning(
              'Avaliação deste desvio possui outro desvio que está em revisão exclusão. Não é possivel alterar o cliente.',
            );
          }
          if (data?.revisao_da_operacao === 'PENDENTE') {
            toast.warning(
              'Avaliação deste desvio está em revisão de troca de operação. Não é possivel excluir.',
            );
          }
        }
      }
    },
    onError: error => toast.error(error?.message),
    refetchOnWindowFocus: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `Desvio_${infraction?.id}`,
  });

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in formData) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (formData[key] || infraction[key]) &&
            formData[key] !== infraction[key]
          ) {
            if (
              (typeof formData[key] === 'string' && formData[key].trim()) ||
              typeof formData[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar desvio?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'APROVAR':
        if (!formData.plano_acao) {
          toast.warning('O campo Plano de Ação é obrigatório!');
          const element = document.getElementById('plano_acao');
          element?.focus();
          return;
        }
        if (!formData.ids_responsaveis) {
          toast.warning('O campo Responsável é obrigatório!');
          const element = document.getElementById('responsavel');
          element?.focus();
          return;
        }
        if (!formData.data_previsao) {
          toast.warning('O campo Previsão de Conclusão é obrigatório!');
          const element = document.getElementById('data_previsao');
          element?.click();
          return;
        }
        if (infraction?.desvio_info?.plano_acao !== formData?.plano_acao) {
          toast.warning('Utilize o botão SALVAR para alterar o plano de ação!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (
          infraction?.desvio_info?.arquivo_plano_acao !==
          formData?.arquivo_plano_acao
        ) {
          toast.warning('Utilize o botão SALVAR para alterar o arquivo!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja aprovar desvio?',
          subtitle: 'O desvio será movido para PENDENTE',
        };
        break;

      case 'ANALISAR':
        objOpen = {
          ...objOpen,
          title: 'Deseja enviar esse desvio para análise?',
          subtitle:
            'O desvio permanecerá na aba "PENDENTES" mas ficará marcado para análise.',
        };
        break;

      case 'RETORNAR':
        break;

      case 'FINALIZAR':
        if (!formData.plano_acao) {
          toast.warning('O campo Plano de Ação é obrigatório!');
          const element = document.getElementById('plano_acao');
          element?.focus();
          return;
        }
        if (!formData.ids_responsaveis) {
          toast.warning('O campo "Responsável" é obrigatório.');
          const element = document.getElementById('ids_responsaveis');
          element?.focus();
          return;
        }
        if (!formData.data_previsao) {
          toast.warning('O campo Previsão de Conclusão é obrigatório!');
          const element = document.getElementById('data_previsao');
          element?.click();
          return;
        }
        if (infraction?.desvio_info?.plano_acao !== formData?.plano_acao) {
          toast.warning('Utilize o botão SALVAR para alterar o plano de ação!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (
          infraction?.desvio_info?.arquivo_plano_acao !==
          formData?.arquivo_plano_acao
        ) {
          toast.warning('Utilize o botão SALVAR para alterar o arquivo!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja finalizar desvio?',
          subtitle: 'O desvio será movido para FINALIZADO',
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Chamada ao trocar distribuidora
  // Abre modal de confirmação de troca
  // value passa o novo id_da_distribuidora
  // av_block diz se a avaliação está bloqueada
  const handleChangeDistribuidora = value => {
    const objOpen = {
      value,
      av_block: false,
      event: 'ALTERAR_DISTRIBUIDORA',
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
      title: 'Tem certeza que deseja alterar o cliente do desvio?',
      subtitle:
        'A alteração será aplicada imediatamente. Se o cliente atual moderar alteração, ela ficará pendente.',
    };

    // desvio com avaliacao
    const disabled =
      infraction.avaliacao && infraction.avaliacao.status !== 'PENDENTE';

    // TODO: params no front?
    // const reviewDist = value !== infraction.id_distribuidora;
    // if (reviewDist) {
    //   objOpen.subtitle =
    //     'A solicitação de alteração de cliente ficará pendente e será enviada à embarcadora para análise. \
    //   \nA alteração será aplicada imediatamente.';
    // }

    if (disabled) {
      objOpen.av_block = true;
      objOpen.title = 'Avaliação bloqueada';
      objOpen.subtitle =
        'A alteração de cliente deve ser feita pela avaliação correspondente. deseja continuar?';
    }
    setOpenModal(objOpen);
  };

  const handleSalvarDistribuidora = async new_dist => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      id_distribuidora: new_dist,
    };
    const res = await salvarDesvio([infraction.id], payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      refetch();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'APROVAR':
        handleAprovar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;

      case 'ANALISAR':
        handleAnalisar(false);
        break;

      case 'ALTERAR_DISTRIBUIDORA':
        // Direciona para avaliação caso esteja bloqueada
        // Se não, troca o id_da_distribuidora
        if (openModal.av_block) {
          navigate(`/avaliacoes/${infraction.id_avaliacao}`);
          setOpenModal(null);
        } else {
          handleSalvarDistribuidora(openModal.value);
        }
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      id_distribuidora: formData.id_distribuidora,
      ids_responsaveis: formData.ids_responsaveis,
      data_previsao: formData.data_previsao,
      plano_acao: formData.plano_acao,
      fator_risco: formData.fator_risco,
      arquivo_plano_acao: formData.arquivo_plano_acao,
      observacao: formData.observacao,
      objeto: formData.objeto,
    };
    const res = await salvarDesvio([infraction.id], payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      refetch();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleAprovar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_distribuidora: formData.id_distribuidora,
      ids_responsaveis: formData.ids_responsaveis,
      data_previsao: formData.data_previsao,
      plano_acao: formData.plano_acao,
      fator_risco: formData.fator_risco,
      arquivo_plano_acao: formData.arquivo_plano_acao,
      observacao: formData.observacao,
      objeto: formData.objeto,
    };
    const res = await aprovarDesvios(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/desvios');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      ids: [infraction.id],
      ids_responsaveis: formData.ids_responsaveis,
      data_previsao: formData.data_previsao,
      data_encerramento: new Date(),
      plano_acao: formData.plano_acao,
      fator_risco: formData.fator_risco,
      arquivo_plano_acao: formData.arquivo_plano_acao,
      observacao: formData.observacao,
      objeto: formData.objeto,
    };
    const res = await finalizarDesvios(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/desvios');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleAnalisar = async () => {
    setLoadingModal(true);
    const res = await analisarDesvios(infraction.id);
    if (res.data?.success) {
      toast.success(res.data?.message);
      refetch();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleRetornar = async ({ observacao_analise }) => {
    setLoadingModal(true);
    const payload = {
      observacao_analise,
    };
    const res = await retornarDesvios(infraction.id, payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      refetch();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setDesvioRetornoModal(null);
  };

  const handleDeletar = async (ids, data) => {
    setLoadingModal(true);
    const res = await excluirDesvio(ids, data);
    if (res.data?.success) {
      if (res.data?.alert) toast.warning(res.data?.alert);
      else toast.success(res.data?.message);
      navigate('/desvios');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setDesvioDeleteModal(null);
  };

  const handleRevisaoExclusao = async () => {
    // 'aprovar' || 'recusar' || 'solicitar-evidencias'
    setLoadingModal(true);
    const body = {
      event: openRevisaoExclusao?.event,
      ids: [infraction.id],
      observacao_distribuidora: openRevisaoExclusao?.observacao_distribuidora,
    };
    const res = await revisaoExclusaoProvider(body);
    if (res.data.success) {
      refetch();
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    setLoadingModal(false);
    setOpenRevisaoExclusao(prev => ({ ...prev, event: false }));
  };

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhe do desvio</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/desvios/${params.id}`)}
            sx={{ marginRight: '1rem' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          {infraction?.id_avaliacao &&
            infraction.desvio_tipo?.desvio_tipo_padrao &&
            !isProvider && (
              <GhostButton
                startIcon={<FindInPageOutlined />}
                size="medium"
                onClick={() =>
                  infraction.desvio_tipo.desvio_tipo_padrao.categoria ===
                  'CABINE'
                    ? navigate(`/avaliacoes/${infraction.id_avaliacao}`)
                    : navigate(
                        `/avaliacoes-descarga/${infraction.id_avaliacao}`,
                      )
                }
                sx={{ marginRight: '1rem' }}
              >
                VER AVALIAÇÃO
              </GhostButton>
            )}

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={handlePrint}
            style={{ marginRight: '1rem' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>

          <NextPrev />
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && formData && (
        <>
          <S.Main>
            {renderHeader()}
            <Grid container spacing="20px" marginTop="10px" ref={componentRef}>
              <Grid item xs={12} sm={12} md={6}>
                <Info
                  infraction={infraction}
                  formData={formData}
                  setFormData={setFormData}
                  refetch={refetch}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Config
                  infraction={infraction}
                  formData={formData}
                  setFormData={setFormData}
                  handleOpenModal={handleOpenModal}
                  setDesvioDeleteModal={setDesvioDeleteModal}
                  setDesvioRetornoModal={setDesvioRetornoModal}
                  openRevisaoExclusao={openRevisaoExclusao}
                  setOpenRevisaoExclusao={setOpenRevisaoExclusao}
                  handleChangeDistribuidora={handleChangeDistribuidora}
                />
              </Grid>
            </Grid>
          </S.Main>
          {
            // Este modal controla todas as confirmações
            // openModal.event define a acao em handleConfirmModal
            openModal && (
              <ConfirmModal
                handleClose={() => setOpenModal(null)}
                open={Boolean(openModal)}
                title={openModal.title}
                subtitle={openModal.subtitle}
                titleIcon={openModal.titleIcon}
                buttonText={openModal.buttonText}
                onClick={handleConfirmModal}
                loading={loadingModal}
              />
            )
          }
          {desvioDeleteModal && (
            <DeleteModal
              handleConfirm={handleDeletar}
              desvio={desvioDeleteModal}
              handleClose={() => setDesvioDeleteModal(null)}
              loading={loadingModal}
            />
          )}
          {desvioRetornoModal && (
            <RetornoModal
              open={desvioRetornoModal}
              handleConfirm={handleRetornar}
              handleClose={() => setDesvioRetornoModal(null)}
              loading={loadingModal}
            />
          )}
          {openRevisaoExclusao?.event && (
            <JustifyModal
              handleClose={() =>
                setOpenRevisaoExclusao(prev => ({ ...prev, event: false }))
              }
              open={openRevisaoExclusao?.event}
              loading={loadingModal}
              title={openRevisaoExclusao?.title}
              subtitle={openRevisaoExclusao?.subtitle}
              buttonText="Confirmar"
              onClick={() => handleRevisaoExclusao()}
              value={openRevisaoExclusao?.observacao_distribuidora}
              onChange={value =>
                setOpenRevisaoExclusao(prev => ({
                  ...prev,
                  observacao_distribuidora: value,
                }))
              }
            />
          )}

          <ExportToPdf
            infraction={infraction}
            printRef={printRefPdf}
            responsavel={users.find(
              user => user.id === infraction?.id_responsavel,
            )}
            cliente={clients.find(
              client => client.id === infraction?.id_distribuidora,
            )}
          />
        </>
      )}
    </>
  );
};

export default DetalheDesvio;
