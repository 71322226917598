import { createTheme } from '@mui/material/styles';
import onsisysPalette from './palette';
import { defaultTheme } from './default';
import typography from './typography';

export const onisysTheme = createTheme({
  palette: onsisysPalette,
  typography,
});

export const getTheme = (themes, primaryId, secondaryId) => {
  // const primaryPaletteName = primaryId
  //   ? themes.find(wl => wl.id == primaryId)?.body
  //   : null;

  // const secondaryPaletteName = secondaryId
  //   ? themes.find(wl => wl.id == secondaryId)?.body
  //   : null;

  // if (primaryPaletteName) {
  //   try {
  //     const remotePrimary = getRemoteConfigByName(primaryPaletteName);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // if (secondaryPaletteName) {
  //   try {
  //     const remoteSecondary = getRemoteConfigByName(secondaryPaletteName);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const primaryPalette = primaryId
    ? whitelabels.find(wl => wl.id == primaryId)
    : null;

  const secondaryPalette = secondaryId
    ? whitelabels.find(wl => wl.id == secondaryId)
    : null;

  const theme = createTheme({
    palette: {
      ...defaultTheme.palette,
      brand: {
        primary: primaryPalette?.palette || {
          ...defaultTheme.palette.brand.primary,
        },
        secondary: secondaryPalette?.palette || {
          ...defaultTheme.palette.brand.secondary,
        },
      },
      primary: primaryPalette
        ? { ...primaryPalette.palette, main: primaryPalette.palette.natural }
        : {
            ...defaultTheme.palette.brand.primary,
            main: defaultTheme.palette.brand.primary.natural,
          },
      secondary: secondaryPalette
        ? {
            ...secondaryPalette.palette,
            main: secondaryPalette.palette.natural,
          }
        : {
            ...defaultTheme.palette.brand.secondary,
            main: defaultTheme.palette.brand.secondary.natural,
          },
    },
    typography,
  });

  return theme;
};

export const whitelabels = [
  {
    id: 1,
    tipo: 1,
    palette: {
      light: '#ffb2d1',
      natural: '#ff0066',
      dark: '#373f54',
      background: '#fff9fb',
    },
  },
  {
    id: 2,
    tipo: 1,
    palette: {
      light: '#fed4bb ',
      natural: '#fe701e',
      dark: '#373f54',
      background: '#fefcfa',
    },
  },
  {
    id: 3,
    tipo: 1,
    palette: {
      light: '#fcf0b4',
      natural: '#f6cf07',
      dark: '#373f54',
      background: '#fffcdc',
    },
  },
  {
    id: 4,
    tipo: 1,
    palette: {
      light: '#bef6e1',
      natural: '#28e19b',
      dark: '#373f54',
      background: '#f8fefc',
    },
  },
  {
    id: 5,
    tipo: 1,
    palette: {
      light: '#b2d8ff',
      natural: '#0080ff',
      dark: '#373f54',
      background: '#f9fcff',
    },
  },
  {
    id: 6,
    tipo: 1,
    palette: {
      light: '#c2cde4',
      natural: '#345aa6',
      dark: '#373f54',
      background: '#fafbfd',
    },
  },
  {
    id: 7,
    tipo: 1,
    palette: {
      light: '#e5b2ff',
      natural: '#ab00ff',
      dark: '#373f54',
      background: '#fdf9ff',
    },
  },
  {
    id: 8,
    tipo: 1,
    palette: {
      light: '#d2d2d2',
      natural: '#6a6a6a',
      dark: '#373f54',
      background: '#f8f8f8',
    },
  },
  {
    id: 9,
    tipo: 2,
    palette: {
      light: '#9dbfd4',
      natural: '#0C5F94',
      dark: '#0F77BA',
      background: '#fafbfc',
    },
  },
  {
    id: 10,
    tipo: 2,
    palette: {
      light: '#bdb6de',
      natural: '#240C94',
      dark: '#373f54',
      background: '#f8f7fb',
    },
  },
  {
    id: 11,
    tipo: 2,
    palette: {
      light: '#deb6d5',
      natural: '#940C73',
      dark: '#373f54',
      background: '#fbf7fa',
    },
  },
  {
    id: 12,
    tipo: 2,
    palette: {
      light: '#eac2f5',
      natural: '#BA34E0',
      dark: '#373f54',
      background: '#fcf8fd',
    },
  },
  {
    id: 13,
    tipo: 2,
    palette: {
      light: '#d2bec8',
      natural: '#6C2749',
      dark: '#373f54',
      background: '#faf8f9',
    },
  },
  {
    id: 14,
    tipo: 2,
    palette: {
      light: '#c8deb6',
      natural: '#4A940C',
      dark: '#373f54',
      background: '#fbfcfa',
    },
  },
  {
    id: 15,
    tipo: 2,
    palette: {
      light: '#b6deca',
      natural: '#0C9450',
      dark: '#373f54',
      background: '#fafcfb',
    },
  },
  {
    id: 16,
    tipo: 2,
    palette: {
      light: '#bed0db',
      natural: '#27638A',
      dark: '#373f54',
      background: '#f8fafb',
    },
  },
];
