/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useEffect, useState } from 'react';

// Components MUI
import SearchIcon from '@mui/icons-material/Search';

// Generators
import { limitText } from '../../_Table/generators/limit';

let timer = null;

export const Input = ({
  placeholder = 'Buscar',
  Icon = SearchIcon,
  loading,
  local = true,
  value,
  handleChange,
  ...props
}) => {
  const [inputValue, setInputvalue] = useState(value);
  const [initial, setInitial] = useState(true);

  const handleSearch = val => {
    setInputvalue(val);
  };

  useEffect(() => {
    if (!initial) {
      const time = local ? 0 : 2000;
      clearTimeout(timer);

      timer = setTimeout(() => {
        handleChange(inputValue);
      }, time);
    } else {
      setInitial(false);
    }
  }, [inputValue]);

  return (
    <S.Container
      onSubmit={e => {
        e.preventDefault();
        clearTimeout(timer);
        handleChange(inputValue);
      }}
    >
      <S.IconContainer>
        <Icon className="icon" data-testid="icon" />
      </S.IconContainer>
      <S.Search
        data-testid="input"
        placeholder={limitText(placeholder, 25)}
        value={inputValue}
        onChange={e => !loading && handleSearch(e.target.value)}
        isLoading={loading}
        {...props}
      />
    </S.Container>
  );
};
