import React, { useCallback, useEffect, useState } from 'react';
import Tabs from 'components/Tabs';
import { Divider } from '@mui/material';
import ListDeslocamentos from '../components/Deslocamento';
import Cards from './Cards';
import { getDeslocamentos, tratarDesvios } from '../Listagem/services';
import { useQuery } from 'react-query';
import { cardsInfo as initialCardsInfo } from './constants';
import { toast } from 'react-toastify';

const Deslocamentos = () => {
  const [tabSelect, setTabSelect] = useState('ABERTO');
  const [filteredDeslocamentos, setFilteredDeslocamentos] = useState([]);
  const [cardsInfo, setCardsInfo] = useState(initialCardsInfo);
  const [selectedCard, setSelectedCard] = useState(null);

  const {
    isFetching: loadingDeslocamento,
    data: resDataDeslocamento,
    refetch,
  } = useQuery(
    ['deslocamentos', tabSelect],
    () => getDeslocamentos({ tipo: tabSelect }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (resDataDeslocamento) {
      const altissimo = resDataDeslocamento?.data?.filter(
        item => item.risco === 'ALTISSIMO',
      )?.length;
      const alto = resDataDeslocamento?.data?.filter(
        item => item.risco === 'ALTO',
      )?.length;
      const perigosos = resDataDeslocamento?.data?.filter(item =>
        ['PERIGO', 'BAIXO'].includes(item.risco),
      )?.length;

      const updatedCardsInfo = initialCardsInfo?.map(card => {
        if (card?.type === 'ALTISSIMO') {
          return { ...card, value: altissimo };
        }
        if (card?.type === 'ALTO') {
          return { ...card, value: alto };
        }
        if (card?.type === 'PERIGO') {
          return { ...card, value: perigosos };
        }
        return card;
      });

      setCardsInfo(updatedCardsInfo);
      setFilteredDeslocamentos(resDataDeslocamento.data);
    }
  }, [resDataDeslocamento]);

  useEffect(() => {
    if (selectedCard === 'ALTISSIMO') {
      setFilteredDeslocamentos(
        resDataDeslocamento.data.filter(item => item.risco === 'ALTISSIMO'),
      );
    } else if (selectedCard === 'ALTO') {
      setFilteredDeslocamentos(
        resDataDeslocamento.data.filter(item => item.risco === 'ALTO'),
      );
    } else if (selectedCard === 'PERIGO') {
      setFilteredDeslocamentos(
        resDataDeslocamento.data.filter(item =>
          ['PERIGO', 'BAIXO'].includes(item.risco),
        ),
      );
    } else {
      setFilteredDeslocamentos(resDataDeslocamento?.data ?? []);
    }
  }, [selectedCard]);

  const handleTratarDesvio = async data => {
    if (tabSelect !== 'ABERTO') return;
    const res = await tratarDesvios(data);
    if (res.success) {
      toast.success(res.message);
      refetch();
    }
  };

  const handleChangeCards = useCallback(type => {
    setSelectedCard(selectedCard => (selectedCard === type ? null : type));
  }, []);

  return (
    <>
      <div
        style={{
          color: '#4B5166',
          fontWeight: '900',
          fontSize: 24,
          marginBottom: 15,
        }}
      >
        Deslocamentos ativos
      </div>
      <Tabs
        value={tabSelect}
        onChange={(e, value) => setTabSelect(value)}
        items={[
          { label: 'Abertos', value: 'ABERTO' },
          { label: 'Tratados', value: 'TRATADO' },
          { label: 'Vencidos', value: 'VENCIDO' },
        ]}
      />
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {cardsInfo.map((item, index) => (
          <Cards
            key={index}
            {...item}
            selected={selectedCard === item.type}
            handleClick={() => handleChangeCards(item.type)}
          />
        ))}
      </div>
      <div>
        <ListDeslocamentos
          data={filteredDeslocamentos}
          onSave={handleTratarDesvio}
          loading={loadingDeslocamento}
        />
      </div>
    </>
  );
};

export default Deslocamentos;
