import { useSelector } from 'react-redux';
import { usePlans } from 'hooks/usePlans';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

export const useFilterOptions = () => {
  const { isProvider, hasTorrePlus } = usePlans();
  const getTransporterFilterOptions = () => {
    const {
      responsaveis,
      filiais,
      clients,
      tecnologias,
      criticidade,
      origin,
      desviosTiposPadrao,
    } = useFetchMultipleWithCache();

    // Filtros utilizados no acesso Transportador
    const transporterFilterOptions = [
      {
        filterName: 'filial',
        label: 'Filiais',
        options: filiais || [],
      },
      {
        filterName: 'filial_veiculo',
        label: 'Filial Veículo',
        options: filiais || [],
      },
      {
        filterName: 'client',
        label: 'Clientes',
        options: clients.data || [],
      },
      {
        filterName: 'tecnologia',
        label: 'Tecnologias',
        options: tecnologias.data || [],
      },
      {
        filterName: 'categorias',
        label: 'Origem',
        options: origin || [],
      },
      {
        filterName: 'criticidade',
        label: 'Criticidades',
        options: criticidade.data || [],
      },
      {
        filterName: 'desvio',
        label: 'Desvios',
        options: desviosTiposPadrao?.data || [],
      },
      {
        filterName: 'responsavel',
        label: 'Responsáveis',
        options: responsaveis?.data || [],
      },
      {
        filterName: 'motoristaNaoDefinido',
        label: 'Motoristas',
        multiple: false,
        options: [
          { label: 'Identificado', value: 0 },
          { label: 'Não identificado', value: 1 },
        ],
      },
    ];
    return transporterFilterOptions;
  };

  const getProviderFilterOptions = () => {
    const {
      responsaveis,
      filiais,
      tecnologias,
      criticidade,
      origin,
      desviosTiposPadrao,
    } = useFetchMultipleWithCache();
    const selects = useSelector(state => state.selects);
    const empresasFilter =
      selects?.empresas?.map(i => {
        return { label: i.nome, value: i.id };
      }) || [];

    // filtros utilizaods no acesso Provider
    const providerFilterOptions = [
      {
        filterName: 'empresas',
        label: 'Empresas',
        options: empresasFilter || [],
      },
      {
        filterName: 'filial',
        label: 'Filiais',
        options: filiais || [],
      },
      {
        filterName: 'filial_veiculo',
        label: 'Filial Veículo',
        options: filiais || [],
      },
      {
        filterName: 'tecnologia',
        label: 'Tecnologias',
        options: tecnologias.data || [],
      },
      {
        filterName: 'categorias',
        label: 'Origem',
        options: origin || [],
      },
      {
        filterName: 'criticidade',
        label: 'Criticidades',
        options: criticidade.data || [],
      },
      {
        filterName: 'desvio',
        label: 'Desvios',
        options: desviosTiposPadrao?.data || [],
      },
      {
        filterName: 'responsavel',
        label: 'Responsáveis',
        options: responsaveis?.data || [],
      },
    ];

    if (isProvider && hasTorrePlus) {
      providerFilterOptions.push({
        filterName: 'motoristaNaoDefinido',
        label: 'Motoristas',
        multiple: false,
        options: [
          { label: 'Identificado', value: 0 },
          { label: 'Não identificado', value: 1 },
        ],
      });
    }

    return providerFilterOptions;
  };

  return isProvider
    ? getProviderFilterOptions()
    : getTransporterFilterOptions();
};
