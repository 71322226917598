// Styles
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setCapa,
  setId,
  setItems,
  setStatus,
  setTitle,
} from 'store/modules/formularios/actions';

// Components
import { Link } from '@mui/material';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as Seguir } from 'images/formulario/seguir.svg';
import { ReactComponent as Seguindo } from 'images/formulario/seguindo.svg';

// Utils
import { toast } from 'react-toastify';
import { formatNewDate } from 'utils/dates';
import { trackEvent } from 'utils/mixpanel';
import ModalTitle from '../ModalTitle';
import * as S from './styled';
import * as services from '../../services';
import * as responses from '../../ParaMim/services';
import {
  calcPercente,
  formatDescription,
  formatRibbon,
  formatStatus,
  formatTitle,
} from '../../actions';

const ContentCard = ({
  template,
  isTemplate,
  closeModalTemplates,
  setFullModal,
  noTags,
  forMe,
  setModalResponse,
  setFormResponse,
  getCurrentResponses,
  loadingHistoryLine,
  handleToggleFollow,
}) => {
  const theme = useTheme();
  let timerReset = null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);

  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [usedTemplate, setUsedTemplate] = useState(null);
  const [screenView, setScreenView] = useState('100%');

  const img = require(`images/defaultFormulario.jpg`);

  // ---------------------------TEXT FORMATING --------------------------//
  const formatedFinsih = date => {
    if (!date) {
      return 'Nunca encerrar';
    }
    if (!forMe) {
      return `Até: ${new Date(date).toLocaleDateString()}`;
    }
    return `Responder em: ${new Date(date).toLocaleDateString()}`;
  };

  const formatMessage = () => {
    if (!template.repetir) {
      return 'Sem repetição';
    }

    let base = null;

    switch (template.periodo) {
      case 'DIA':
        base = template.frequencia > 1 ? 'dias' : 'dia';
        break;
      case 'SEMANA':
        base = template.frequencia > 1 ? 'semanas' : 'semana';
        break;
      case 'MES':
        base = template.frequencia > 1 ? 'meses' : 'mes';
        break;
    }

    const mas = ['Sabado', 'Domingo'];
    const fem = ['Segunda', 'Terca', 'Quarta', 'Quinta', 'Sexta'];
    let textDay = null;
    if (
      template.periodo === 'SEMANA' &&
      template.dias_semana &&
      mas.includes(template.dias_semana)
    ) {
      textDay = `todo ${template.dias_semana.toLowerCase()},`;
    } else if (
      template.periodo === 'SEMANA' &&
      template.dias_semana &&
      fem.includes(template.dias_semana)
    ) {
      textDay = `toda ${template.dias_semana.toLowerCase()},`;
    }

    return `A cada ${template.frequencia} ${base}, ${textDay || ''} ${
      template.data_fim
        ? `até ${new Date(template.data_fim).toLocaleDateString()}`
        : 'nunca encerrar'
    }`;
  };

  // -------------------------CALLED BY TEMPLATE -----------------------//
  const getSelectedTemplate = async id => {
    setLoading(true);
    const res = await services.getTemplate(id);
    if (res.success) {
      setUsedTemplate(res.data.secoes);
      setModalTitle(true);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  // -------------------------CALLED BY LIST -----------------------//
  const editForm = async (id, status) => {
    if (status !== 'Rascunho') {
      navigate(`/formularios/configuracao/editar/${id}`);
      return;
    }
    setLoading(true);
    const res = await services.getFomrItem(id);
    if (res.success) {
      const { form } = res.data;
      // TODO: Unificar disptachs
      dispatch(setTitle(form.titulo));
      dispatch(setCapa(form.capa));
      dispatch(setItems(form.secoes));
      dispatch(setStatus(form.status));
      dispatch(setId(form?.id));
      setFullModal(true);
      window.history.replaceState({}, {}, '/formularios/criar');
    } else {
      toast.error(res.success);
    }
    setLoading(false);
  };

  // -------------------------CALLED BY FORM ME -----------------------//
  const responseForm = async id => {
    setLoading(true);
    const res = forMe
      ? await responses.getFormResponse(id)
      : await responses.getFormPreview(id);

    if (res.success) {
      setFormResponse(res.data);
      setModalResponse(true);
    } else {
      toast.error(res.message || 'Erro ao carregar formulário');
    }
    setLoading(false);
  };

  const resetScreenHistory = () => {
    if (loadingHistoryLine || screenView === '100%') return;

    clearTimeout(timerReset);
    timerReset = setTimeout(() => {
      setScreenView('100%');
    }, 1000);
  };

  return (
    <>
      <S.Wrapper
        className="cardContent"
        charge={loading}
        noTags={noTags}
        onMouseLeave={() => resetScreenHistory()}
        onMouseEnter={() => clearTimeout(timerReset)}
      >
        {loading && (
          <S.LoadingBox>
            <Loading />
          </S.LoadingBox>
        )}
        <>
          <S.Image
            src={template.capa || img}
            finished={
              !noTags &&
              (template.form_status === 'Finalizado' ||
                template.form_status === 'Concluido' ||
                template.status === 'Finalizado' ||
                template.status === 'Concluido')
            }
          />
          {forMe && (
            <S.Ribbon
              {...formatRibbon(
                template.status,
                template.form_status,
                template.data_fim_atual,
                template.data_inicio_proximo,
                template.data_inicio_atual,
                theme,
              )}
            />
          )}
          <S.Content
            screenView={screenView}
            className="content"
            noTags={noTags}
            finished={
              !noTags &&
              (template.form_status === 'Finalizado' ||
                template.form_status === 'Concluido' ||
                template.status === 'Finalizado' ||
                template.status === 'Concluido')
            }
            inLineLoading={loadingHistoryLine}
          >
            <div className="screenPrimary">
              {!noTags && (
                <S.TagsArea>
                  {template?.tags.map((tag, key) => {
                    if (key > 2) {
                      return null;
                    }
                    if (tag.length > 14) {
                      return (
                        <S.Tag key={key}>{`${tag.slice(0, 10)}...`}</S.Tag>
                      );
                    }
                    return <S.Tag key={key}>{tag}</S.Tag>;
                  })}
                </S.TagsArea>
              )}

              <h6 className="title">{formatTitle(template.titulo)}</h6>
              <p className="description">
                {formatDescription(template.descricao)}
              </p>
              {!noTags && (
                <span className="statusBox">
                  {formatStatus(template.status, theme)}
                  {forMe && (
                    <S.ProgressBar>
                      {calcPercente(
                        template.status !== 'Agendado' &&
                          template.status !== 'Pendente'
                          ? template?.historico[0]?.percentual
                          : 0,
                        theme,
                      )}
                    </S.ProgressBar>
                  )}
                  {!forMe && template.isFollowing !== null && (
                    <S.IconLink following={template.isFollowing}>
                      {template.isFollowing ? (
                        <>
                          <Seguindo />
                          <Link
                            component="button"
                            onClick={() =>
                              handleToggleFollow?.(template.id, false)
                            }
                            color="inherit"
                          >
                            Seguindo
                          </Link>
                        </>
                      ) : (
                        <>
                          <Seguir />
                          <Link
                            component="button"
                            onClick={() =>
                              handleToggleFollow?.(template.id, true)
                            }
                            color="inherit"
                          >
                            Não segue
                          </Link>
                        </>
                      )}
                    </S.IconLink>
                  )}
                </span>
              )}

              <S.Buttons noTags={noTags} forMe={forMe}>
                {!forMe && (
                  <GhostButton
                    children="Visualizar"
                    style={{ width: '100%', marginBottom: '10px' }}
                    onClick={() => {
                      trackEvent(user, 'FORMULÁRIOS: VISUALIZAR');
                      responseForm(template?.id);
                    }}
                  />
                )}

                {!isTemplate && !forMe && (
                  <DefaultButton
                    children="Editar"
                    style={{ width: '100%' }}
                    onClick={() => {
                      trackEvent(user, 'FORMULÁRIOS: EDITAR');
                      editForm(template?.id, template.status);
                    }}
                  />
                )}

                {isTemplate && !forMe && (
                  <DefaultButton
                    children="Selecionar Template"
                    style={{ width: '100%' }}
                    onClick={() => getSelectedTemplate(template?.id)}
                  />
                )}

                {forMe && (
                  <div
                    className="warnToast"
                    onClick={
                      template.form_status === 'Agendado'
                        ? () =>
                            toast.warn(
                              `Disponível para respostas em ${
                                new Date() >
                                new Date(template.data_inicio_atual)
                                  ? new Date(
                                      template.data_inicio_proximo,
                                    ).toLocaleDateString()
                                  : template.data_inicio_atual
                                  ? new Date(
                                      template.data_inicio_atual,
                                    ).toLocaleDateString()
                                  : 'breve'
                              }`,
                            )
                        : undefined
                    }
                  >
                    <DefaultButton
                      children={
                        template.form_status !== 'Finalizado' &&
                        template.form_status !== 'Concluido'
                          ? 'Preencher formulário'
                          : `Formulário ${template.form_status}`
                      }
                      style={{ width: '100%' }}
                      onClick={() => {
                        trackEvent(user, 'FORMULÁRIOS: PREENCHER FORMÚLARIO');
                        responseForm(template?.id);
                      }}
                      disabled={
                        template.form_status === 'Agendado' ||
                        template.form_status === 'Finalizado' ||
                        template.form_status === 'Concluido'
                      }
                    />
                  </div>
                )}

                {!isTemplate && forMe && !!template.historico.length && (
                  <DefaultButton
                    onClick={() => {
                      trackEvent(user, 'FORMÚLARIOS: VER HISTÓRICO');
                      setScreenView('-100%');
                    }}
                    style={{ marginTop: '10px', width: '100%' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>Ver histórico</div>
                      <ArrowForwardIosIcon
                        htmlColor={theme.palette.system.overlay}
                        fontSize="16px"
                      />
                    </div>
                  </DefaultButton>
                )}

                {!noTags && (
                  <span>
                    {formatedFinsih(
                      template.data_fim || template.data_fim_atual
                        ? template.data_inicio_atual
                        : null,
                    )}
                    , {formatMessage()}
                  </span>
                )}
              </S.Buttons>
            </div>

            <div className="screenSecondary">
              <S.HeaderStory>
                <div className="left" onClick={() => setScreenView('100%')}>
                  <ArrowBackIosIcon
                    htmlColor={theme.palette.brand.secondary.natural}
                  />
                  <S.TitleStory>Minhas Respostas</S.TitleStory>
                </div>
                <div
                  className="right"
                  onClick={() =>
                    navigate(`/fomularios/historicos/${template.id}`)
                  }
                >
                  Ver todos
                </div>
              </S.HeaderStory>
              <S.LineArea>
                {forMe &&
                  template?.historico?.map((history, key) => (
                    <S.HistoryItem
                      key={key}
                      onClick={() =>
                        getCurrentResponses(history?.id, template.id)
                      }
                    >
                      {loadingHistoryLine !== history?.id && (
                        <>
                          <S.HistoryText>
                            <div className="text">
                              {' '}
                              {history?.concluido
                                ? 'Concluído em:'
                                : 'Iniciado em:'}
                            </div>{' '}
                            <span className="date">
                              {formatNewDate(
                                history?.concluido
                                  ? history.data_concluido
                                  : history?.createdAt,
                              )}
                            </span>
                          </S.HistoryText>
                          <S.HistoryStatus concluido={history?.concluido}>
                            {history?.concluido ? 'Concluído' : 'Não Concluído'}
                          </S.HistoryStatus>
                        </>
                      )}
                      {loadingHistoryLine === history?.id && (
                        <S.LoadingBoxLine>
                          <Loading />
                        </S.LoadingBoxLine>
                      )}
                    </S.HistoryItem>
                  ))}
              </S.LineArea>
            </div>
          </S.Content>
        </>
      </S.Wrapper>
      <ModalTitle
        open={modalTitle}
        onClose={() => setModalTitle(false)}
        setFullModal={setFullModal}
        template={usedTemplate}
        closeModalTemplates={closeModalTemplates}
      />
    </>
  );
};

export default ContentCard;
