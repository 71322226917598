// Styles

// React
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reset } from 'store/modules/formularios/actions';

// Page Components
import ExcelModal from 'components/ExcelModalNew';
import DefaultButton from 'components/Buttons/Default';
import { Divider } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { formatNewDate } from 'utils/dates';
import { trackEvent } from 'utils/mixpanel';
import { useDebounce } from 'use-debounce';
import Tag from './components/Tag';
import Title from './components/Title';
import Search from './components/Search';
import Loading from './components/Loading';
import ModalAdd from './components/ModalAdd';
import FullModal from './components/FullModal';
import ModalTitle from './components/ModalTitle';
import ModalResponse from './components/ModalResponse';
import ModalTemplates from './components/ModalTemplates';
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Geral Components
import Templates from './components/Templates';
import SliderTag from './components/SliderTag';

// Material Components

// Utils
import * as services from './services';
import { fields } from './constants';
import * as S from './styled';
import { generateFileName } from 'utils/generateFileName';

let timer = null;
let timerRoute = null;

const Formularios = () => {
  const { getTurmasStatus, getTurmas } = useFetchMultipleWithCache();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname.slice(13);
  const formularios = useSelector(state => state.formularios);
  const filter = useSelector(state => state.filter.filters);
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  // General States
  const [firstCharge, setFirstCharge] = useState(true);
  const [templates, setTemplates] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [tags, setTags] = useState([]);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [formResponse, setFormResponse] = useState(null);
  const [fullModal, setFullModal] = useState(false);
  const [inSearch, setInSearch] = useState(false);
  const [query, setQuery] = useState({
    card: null,
    tag: 'Todos',
    search: '',
  });
  const [searchTrackEvent] = useDebounce(query.search, 1000);
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.formularios;
  });

  // -------------------------------------PAGE CONTROLLER---------------------------------------//

  useEffect(() => {
    if (formularios.id && pathname === 'criar') {
      setFullModal(true);
    } else if (pathname === 'novo') {
      setModalAdd(true);
      window.history.replaceState({}, {}, '/formularios');
    }
  }, []);

  const {
    data: res,
    isError,
    isLoading: loading,
    refetch,
  } = useQuery(['forms', 1], () => services.GetForms(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading) return null;

    if (!isError) {
      setTemplates(res.data.forms);
      setTags(res.data.tags);
      if (pathname !== 'criar') dispatch(reset());
    } else {
      toast.error(res.message);
    }
  }, [res]);

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!fullModal && !firstCharge && !modalResponse) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [fullModal, firstCharge, modalResponse]);

  // ------------------------------------FILTERS CONTROLLER-------------------------------------//
  const handleQuery = object => {
    setQuery({ ...query, ...object });
  };

  useEffect(() => {
    if (filter) setQuery({ ...query, ...filter?.formularios });
  }, [filter?.formularios]);

  useEffect(() => {
    let hasFilter = [];
    const temps = templates;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredTemplates(null);
      setFirstCharge(false);
      return;
    }

    // Filtro Status
    status = query?.status ? query.status : false;
    if (status.length) {
      hasFilter = temps.filter(temp => status.includes(temp?.status));
      if (status.includes('Inativo')) {
        hasFilter = hasFilter.concat(temps.filter(temp => !temp.ativo));
      }
    } else {
      hasFilter = temps.filter(temp => temp.ativo);
    }

    // Filtro Publico
    const turma = query?.turma;
    let turmaLowerCase = '';

    if (Array.isArray(turma) && typeof turma[0] === 'string') {
      turmaLowerCase = turma[0].toLowerCase();
    }

    if (turmaLowerCase.includes('motoristas')) {
      // Verifica o valor convertido para minúsculas
      const hasMotoristas = hasFilter.filter(item => {
        return (
          item.turmas &&
          item.turmas.some(
            turma =>
              turma.para_motoristas === true &&
              turma.tipoUser.toLowerCase() === 'motorista',
          )
        );
      });
      hasFilter = hasMotoristas;
    } else if (turmaLowerCase.includes('gestores')) {
      // Verifica o valor convertido para minúsculas
      const hasGestor = hasFilter.filter(item => {
        return (
          item.turmas &&
          item.turmas.some(
            turma =>
              turma.para_usuarios === true &&
              turma.tipoUser.toLowerCase() === 'gestor',
          )
        );
      });
      hasFilter = hasGestor;
    }

    if (query.search) {
      hasFilter = hasFilter.filter(temp => {
        const turmasName = temp.turmas
          .map(item => item.nome.toLowerCase())
          .join('*');
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          turmasName.includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter.filter(temp => temp.tags.includes(query.tag));
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' && !status && !turma) {
        setFilteredTemplates(null);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates]);

  useEffect(() => {
    // Valida busca com pelo menos 3 caracteres para o MixPanel
    if (searchTrackEvent.length >= 3) {
      trackEvent(user, 'Busca Formulários', null, searchTrackEvent);
    }
  }, [searchTrackEvent]);

  // -------------------------- EXCEL ------------------------------------------//

  // const handleRequestExcel = async () => {
  //   const newFields = excelFields.filter(item => item.selected === true);
  //   const formatedDate = formatNameDate(new Date());

  //   const excelArr = [];
  //   const data = filteredTemplates || templates;
  //   data.forEach(template => {
  //     let customizedFields = {};
  //     const multiplo = template.multiplo;

  //     newFields.forEach(item => {
  //       const { label } = item;
  //       let value =
  //         template[item.value] || template[item.value] === 0
  //           ? template[item.value]
  //           : '';

  //       // Formatação de value para datas
  //       if (
  //         label === 'Criado em' ||
  //         label === 'Data Encerramento' ||
  //         label === 'Última edição' ||
  //         label === 'Data Início'
  //       )
  //         value = formatNewDate(value);

  //       if (
  //         label === 'Gera Ocorrências' ||
  //         label === 'Obrigatório' ||
  //         label === 'Necessita Aprovação' ||
  //         label === 'Múltiplo'
  //       )
  //         value = value ? 'Sim' : 'Não';

  //       if (
  //         label === 'Responsável aprovação' ||
  //         label === 'Empresa' ||
  //         label === 'Criador' ||
  //         label === 'Responsável ocorrência'
  //       )
  //         value = value.nome;

  //       // Se for múltiplo ignora periodo e frequencia para não confundir
  //       if (multiplo && (label === 'Frequência' || label === 'Período'))
  //         value = '--';

  //       customizedFields = { ...customizedFields, [label]: value };
  //     });
  //     excelArr.push(customizedFields);
  //   });

  //   console.log('ExcelArr', excelArr);
  //   console.log('ExcelField', excelFields);

  //   setLoadingExcel(true);
  //   ExportToExcel({
  //     excel: excelArr,
  //     name: `formularios_${formatedDate}`,
  //   });
  //   setExcelFields(resetFields(fields));
  //   setLoadingExcel(false);
  //   setOpenExcelModal(false);
  // };

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [localData, setLocalData] = useState(null);

  const handleRequestExcel = async () => {
    const excelArr = [];
    const data = templates;

    const filteredData = data.filter(template => {
      const { initialDate, finalDate, turma, status } = filtersPersist || {};

      // Filtra por data
      // if (initialDate && finalDate) {
      //   const templateDate = new Date(template.dataInicio); // Substitua "dataInicio" pela chave correspondente no seu template
      //   if (
      //     templateDate < new Date(initialDate) ||
      //     templateDate > new Date(finalDate)
      //   ) {
      //     return false;
      //   }
      // }

      // Filtra por turma
      console.log('turma filtro', turma);
      console.log('turma', template?.turmas[0]?.tipoUser);
      if (turma && turma.length > 0 && template?.turmas?.[0]?.tipoUser) {
        const tipoUserLower = template?.turmas[0]?.tipoUser
          ?.toLowerCase()
          .trim();
        const turmaLower = turma.map(item => item.toLowerCase().trim());

        if (!turmaLower.includes(tipoUserLower)) {
          return false;
        }
      }

      // Filtra por status
      if (status && status.length > 0 && !status.includes(template.status)) {
        return false;
      }

      return true;
    });

    // Processa os dados filtrados
    filteredData.forEach(template => {
      let customizedFields = {};
      const multiplo = template.multiplo;

      excelFields.forEach(item => {
        const { label } = item;
        let value =
          template[item.value] || template[item.value] === 0
            ? template[item.value]
            : '';

        // Formatação de value para datas
        if (
          label === 'Criado em' ||
          label === 'Data Encerramento' ||
          label === 'Última edição' ||
          label === 'Data Início'
        )
          value = formatNewDate(value);

        if (
          label === 'Gera Ocorrências' ||
          label === 'Obrigatório' ||
          label === 'Necessita Aprovação' ||
          label === 'Múltiplo'
        )
          value = value ? 'Sim' : 'Não';

        if (
          label === 'Responsável aprovação' ||
          label === 'Empresa' ||
          label === 'Criador' ||
          label === 'Responsável ocorrência'
        )
          value = value.nome;

        // Se for múltiplo ignora periodo e frequencia para não confundir
        if (multiplo && (label === 'Frequência' || label === 'Período'))
          value = '--';

        customizedFields = { ...customizedFields, [label]: value };
      });
      excelArr.push(customizedFields);
    });
    return excelArr;
  };

  // Função para buscar os dados do Excel
  useEffect(() => {
    const fetchExcelData = async () => {
      setLoadingExcel(true);
      try {
        const data = await handleRequestExcel(); // Aguarda a resolução da função assíncrona
        setLocalData(data);
      } catch (error) {
        console.error('Erro ao buscar dados do Excel:', error);
      } finally {
        setLoadingExcel(false);
      }
    };

    if (openExcelModal) {
      fetchExcelData(); // Carrega os dados sempre que o modal for aberto
    }
  }, [openExcelModal]); // Executa o efeito quando `openExcelModal` muda

  const filters = [
    {
      filterName: 'turma',
      label: 'Turma',
      options: getTurmas?.data || [],
    },
    {
      filterName: 'status',
      label: 'Status',
      options: getTurmasStatus?.data || [],
    },
  ];

  const handleToggleFollow = async (id, follow) => {
    const res = await services.toggleFollow(id, follow);
    if ([200, 201].includes(res.status)) {
      toast.success(res.data?.message);
    } else {
      toast.error(res.data?.message);
    }
    refetch();
  };

  // ------------------------------------- RENDER ------------------------------------------//

  return (
    <S.Container>
      <Title>Formulários</Title>

      <S.Header>
        <FiltersGlobal
          hideRefleshButton
          hideDate
          persistDate
          data={filters}
          customComponent={
            <span className="buttonArea">
              <DefaultButton
                children="Novo Formulário"
                onClick={() => {
                  trackEvent(user, 'FORMULÁRIOS: CRIAÇÃO');
                  setModalAdd(true);
                }}
                startIcon={<AddCircleOutline />}
                disabled={isConvidado}
              />
            </span>
          }
          handleExport={() => {
            trackEvent(user, 'FORMULÁRIOS: EXPORTAR');
            setOpenExcelModal(true);
          }}
        />
      </S.Header>

      <Divider style={{ marginTop: '10px' }} />

      <SliderTag width="72vw">
        {!loading &&
          tags.map((item, key) => (
            <Tag
              children={item}
              key={key}
              onClick={() => handleQuery({ tag: item })}
              active={item === query.tag}
              disabled={item === query.tag}
            />
          ))}
        {loading && <Tag loading />}
      </SliderTag>

      <S.SearchArea>
        <Search
          value={query.search}
          setValue={value => handleQuery({ search: value })}
        />
        <p>{query.tag}</p>
      </S.SearchArea>
      <S.Body inSearch={inSearch}>
        {!loading && templates && !fullModal && !modalResponse && (
          <Templates
            templates={
              filteredTemplates || templates.filter(temp => temp.ativo)
            }
            conteudos={null}
            setModalAdd={setModalAdd}
            query={query}
            inSearch={inSearch}
            setFullModal={setFullModal}
            setFormResponse={setFormResponse}
            setModalResponse={setModalResponse}
            handleToggleFollow={handleToggleFollow}
          />
        )}
        {loading && <Loading />}
      </S.Body>

      {/* Modal Area */}
      <ModalAdd
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        setModalTitle={setModalTitle}
        setModalTemplate={setModalTemplate}
      />

      <ModalTitle
        open={modalTitle}
        onClose={() => {
          setModalTitle(false);
        }}
        setFullModal={setFullModal}
      />

      {fullModal && (
        <FullModal
          open={fullModal}
          onClose={() => {
            setFullModal(false);
            dispatch(reset());
            refetch();
          }}
          backPath="/formularios"
        />
      )}

      {modalResponse && formResponse && (
        <ModalResponse
          open={modalResponse}
          onClose={() => setModalResponse(false)}
          form={formResponse}
          previewWeb
          backPath="/formularios"
        />
      )}
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={excelFields}
          loading={loadingExcel}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route="/excel/NãoTemService"
          filtersPersis={filtersPersist}
          collumns={filters}
          isLocal
          localData={localData}
        />
      )}

      <ModalTemplates
        tags={tags}
        templates={templates}
        open={modalTemplate}
        onClose={() => setModalTemplate(false)}
        setModalTitle={setModalTitle}
        setFullModal={setFullModal}
        setModalResponse={setModalResponse}
        setFormResponse={setFormResponse}
      />
    </S.Container>
  );
};

export default Formularios;
