import api from 'services/api';
import qs from 'qs';

export const getInfosByPeriod = async (id, query) => {
  const res = await api.get(`/form/v1/list/${id}`, { params: query });
  return res.data.data;
};

export const getTiposCampoUsadosNoForm = async id => {
  const res = await api.get(`/form/v1/tipos-campo/${id}`);
  return res.data.data;
};

export const getCardsByPeriod = async (id, query) => {
  const res = await api.get(`/form/v1/cards/${id}`, { params: query });
  return res.data.data;
};

export const getGraphs = async (id, query) => {
  const res = await api.get(`/form/v1/graphs/${id}`, { params: query });
  return res.data;
};

export const signApprove = async (id, query) => {
  const res = await api.put(`/approve-form-hist/${id}?${qs.stringify(query)}`);
  return res.data;
};

export const getFormResponse = async (id, histId, alunoId, alunoTipo) => {
  const res = await api.get(
    `/my-form/${id}?${qs.stringify({
      selects: true,
      histId,
      alunoId,
      alunoTipo,
    })}`,
  );
  return res.data;
};

export const justify = async (id, data) => {
  const res = await api.post(`/form/v1/justify/${id}`, data);
  return res.data;
};
