import { formatNewHourClean } from 'utils/dates';
import { ReactComponent as Analise } from 'images/icons/tooltip/analise.svg';
import { ReactComponent as VideoPendente } from 'images/icons/tooltip/video_pendente.svg';
import { hasReview } from './constants';

export const columns = [
  {
    header: 'Motorista/Filial',
    id: 'motorista_nome',
    type: 'string',
    tooltip: true,
    sort: true,
    conditional: [
      {
        condition: (_, item) => !!item.id_motorista,
        style: theme => ({
          color: theme?.palette?.words.title.natural,
        }),
      },
      {
        condition: (_, item) => !item.id_motorista,
        style: theme => ({
          color: theme?.palette?.words.subtitle.light,
        }),
      },
    ],
    subRow: {
      children: (_, item) =>
        item.id_motorista ? (
          <span style={{ textTransform: 'none' }}>
            Filial: {item?.filial_nome}
          </span>
        ) : null,
    },
  },
  {
    header: 'Placa',
    id: 'placa',
    type: 'string',
    sort: true,
    width: 130,
    style: {
      textTransform: 'uppercase',
    },
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item?.filial_placa,
    },
  },
  {
    header: 'Desvio',
    id: 'titulo',
    type: 'string',
    sort: true,
    tooltip: true,
    showInDetail: true,
    subRow: {
      prefix: 'ID:',
      value: (_, item) => String(item?.id).substring(0, 8),
    },
  },
  {
    header: 'Data',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewHourClean(item?.data_desvio),
  },
  {
    header: 'Vencimento',
    id: 'data_vencimento',
    type: 'string',
    sort: true,
    showInDetail: true,
    value: (_, item) => formatNewHourClean(item?.data_vencimento),
  },
  {
    header: 'Responsável',
    id: 'responsavel_nome',
    type: 'string',
    sort: false,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'criticidade',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => value === 'PRIMÁRIA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.success?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.success?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Inf.',
    id: 'em_analise',
    type: 'string',
    width: 70,
    sort: true,
    value: () => ' ',
    align: 'center',
    attention: (_, item) => {
      if (item.em_analise)
        return {
          text:
            item.em_analise === 'ANALISE'
              ? 'Desvio em análise.'
              : 'Desvio com retorno de análise.',
          iconIn: <Analise />,
          iconOut: <Analise />,
        };
      const msg = hasReview(item);

      if (msg)
        return {
          text: msg,
        };

      if (!(item.imagem || item.video || item.arquivo_evidencia))
        return {
          iconIn: null,
          iconOut: <VideoPendente />,
          text: 'Sem mídia.',
        };
    },
  },
];

export const columnsEmbarcador = [
  {
    header: 'Empresa',
    id: 'empresa_nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial_nome,
    },
  },
  ...columns.slice(1),
];
